import jsonBeautify from 'json-beautify'

const prettifyJsonString = (string) => {
  if (!string) return ''

  let str = string.replaceAll('\\', '')

  // Remove the double quotes to make the fields below be object or array
  str = str.replace(/("(?:message|betLimit)": ?)"{(.*)}"/, '$1{$2}') // key == message || betLimit 的 "{}"
  str = str.replace(/("(?!message)\w+": ?)(.?)"{(.*?)}"(.?)/g, '$1$2{$3}$4') // key != message 的 "{}"
  str = str.replace(/("(?!message)\w+": ?)(.?)"\[(.*?)\]"(.?)/g, '$1$2[$3]$4') // key != message 的 "[]"

  try {
    str = JSON.parse(str)
    str = jsonBeautify(str, null, 2, 1)
  } catch (error) {
    console.error(error)
  }

  // Add the double quotes back to make the fields below be string
  // - message "{}"
  // - settleInfo "{}" (LUCKYPOKER, PTLIVE)
  // - betInfo ["{}"] (LUCKYPOKER, PTLIVE)
  // - betGameModes "[]" (VIACASINO)
  str = str.replace(/("(?:message|betLimit)": ?){([\d\D\s]*)}(\n})/g, '$1"{$2}"$3') // greedy
  str = str.replace(/"settleInfo": ?{([\d\D\s]*?)}(?!,)/g, '"settleInfo": "{$1}"')
  str = str.replace(/"betInfo": ?\[(\s*){([\d\D\s]*?)}(\s*)]/g, '"betInfo": [$1"{$2}"$3]')
  str = str.replace(/"betGameModes": ?\[(\s*)([\d\D\s]*?)\](?!,)/g, '"betGameModes": "[$1$2]"')

  return str
}

export default prettifyJsonString
